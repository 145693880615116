import React from 'react'

import ContentCard from '../../../react4xp/components/ContentCard'

function ContentCardPart({ title, intro, bgColor, position, image, tilt, imageShadow, heartSticker, url }) {
    return (
        <ContentCard
            section={{ bgColor: bgColor }}
            image={{
                position: position,
                src: image?.link,
                tilt: tilt,
                alt: image?.alt,
                redOutline: imageShadow,
                hasHeartIcon: heartSticker
            }}
            title={title}
            text={intro}
            href={url.href}
            external={url.isExternal}
        />
    )
}

export default (props) => <ContentCardPart {...props} />
